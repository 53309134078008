import React, { useState, useEffect, useRef } from "react";
import { classnames, isFirefox, isMobile, saveItemToLocalStorage } from "Utils";
import { LOCAL_STORAGE, SESSION_STORAGE, WORKSTATION_FRIENDLY_STATUS } from "Constants/global.constants";
import { getItemFromSessionStorage, removeItemFromLocalStorage } from "Utils/Helpers/storage.helpers";
import { DivButton, Dropdown, Icon } from "UI";
import { Translate } from "react-localize-redux";
import streamsLogo from "Assets/images/appStreaming/streams-logo.png";
import { SOUND_AND_MICROPHONE_OPTIONS } from "Constants/AppStreaming.constants";
import streamsLogoDark from "Assets/images/appStreaming/streams-logo-dark.png";
import darkStaticBackground from "Assets/images/appStreaming/dark-static-background.jpg";
import lightStaticBackground from "Assets/images/appStreaming/light-static-background.jpg";

import "./AppStreamingLoader.styles.scss";

const AppStreamingLoader = ({
  dark,
  status,
  enterprise,
  bannerURL,
  logoURL,
  connected,
  showPlayButton,
  handlePlayButtonClick,
  texts,
  initialPercentage,
  microphone,
  translate,
}) => {
  const [percentage, setPercentage] = useState(initialPercentage || 0);
  const [text, setText] = useState(texts.initializing_text);
  const [audioInputDeviceArray, setAudioInputDeviceArray] = useState([]);
  const [audioOutputDeviceArray, setAudioOutputDeviceArray] = useState([]);
  const [defaultAudioInputDevice, setDefaultAudioInputDevice] = useState();
  const [defaultAudioOutputDevice, setDefaultAudioOutputDevice] = useState();

  const detectDevicesInterval = useRef(null);

  useEffect(() => {
    let progressBar50pctTimeout;
    let progressBar85pctTimeout;
    let progressBar100pctTimeout;
    let turningOnProgress;
    let installingProgress;
    let gettingReadyProgress;
    if (status === WORKSTATION_FRIENDLY_STATUS.TURNING_ON) {
      turningOnProgress = setInterval(() => {
        setPercentage((percentage) => {
          if (percentage < 50) return percentage + 1;
          return percentage;
        });
      }, 2400);
      progressBar50pctTimeout = setTimeout(() => clearInterval(turningOnProgress), 120000);
    } else if (status === WORKSTATION_FRIENDLY_STATUS.INSTALLING) {
      setText(texts.installing_application_text);
      installingProgress = setInterval(() => {
        setPercentage((percentage) => {
          if (percentage < 50) return 50;
          if (percentage < 85) return percentage + 1;
          return percentage;
        });
      }, 1600);
      progressBar85pctTimeout = setTimeout(() => clearInterval(installingProgress), 50000);
    } else if (status === WORKSTATION_FRIENDLY_STATUS.PREPARING_ASSETS) {
      // TODO: We need to get this from the backend
      setText(translate("appStreamingConnecting.content.preparing_assets"));
    } else if (status === WORKSTATION_FRIENDLY_STATUS.READY) {
      setText(texts.connecting_text);
      gettingReadyProgress = setInterval(() => {
        setPercentage((percentage) => {
          if (percentage < 85) return 85;
          if (percentage < 100) return percentage + 1;
          return percentage;
        });
      }, 2000);
      progressBar100pctTimeout = setTimeout(() => clearInterval(gettingReadyProgress), 15000);
    }
    return () => {
      clearTimeout(progressBar50pctTimeout);
      clearTimeout(progressBar85pctTimeout);
      clearTimeout(progressBar100pctTimeout);
      clearInterval(turningOnProgress);
      clearInterval(installingProgress);
      clearInterval(gettingReadyProgress);
    };
  }, [status]);

  const detectDevices = async () => {
    let devices = [];
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      devices = await navigator.mediaDevices.enumerateDevices();
    } catch (error) {
      clearInterval(detectDevicesInterval.current);
      return;
    }

    const audioInputDevices = devices.filter((device) => device.kind === "audioinput");
    const audioOutputDevices = devices.filter((device) => device.kind === "audiooutput");
    setAudioInputDeviceArray(audioInputDevices || []);
    setAudioOutputDeviceArray(audioOutputDevices || []);
  };

  useEffect(() => {
    const inputDevices = audioInputDeviceArray.filter(
      (device) => device.deviceId === "default" || device.deviceId === "communications",
    );

    let inputDevice = inputDevices.find((device) => device.deviceId === "communications");

    if (!inputDevice) {
      inputDevice = inputDevices.find((device) => device.deviceId === "default");
    }

    if (!defaultAudioInputDevice && inputDevice) {
      setDefaultAudioInputDevice(inputDevice);
      saveItemToLocalStorage(LOCAL_STORAGE.audioInputDevice, inputDevice, true);
    }
  }, [JSON.stringify(audioInputDeviceArray)]);

  useEffect(() => {
    const outputDevice = audioOutputDeviceArray.filter(
      (device) => device.deviceId === "communications" || device.deviceId === "default",
    )[0];

    if (!defaultAudioOutputDevice && outputDevice) {
      setDefaultAudioOutputDevice(outputDevice);
      saveItemToLocalStorage(LOCAL_STORAGE.audioOutputDevice, outputDevice, true);
    }
  }, [JSON.stringify(audioOutputDeviceArray)]);

  useEffect(() => {
    const micEnabled = getItemFromSessionStorage(SESSION_STORAGE.micEnabled);

    if (connected && (microphone === SOUND_AND_MICROPHONE_OPTIONS.activate_on_start || micEnabled)) {
      detectDevices();

      detectDevicesInterval.current = setInterval(() => {
        detectDevices();
      }, 1000);
    }
    return () => clearInterval(detectDevicesInterval.current);
  }, [microphone, defaultAudioInputDevice, connected]);

  useEffect(() => {
    removeItemFromLocalStorage(LOCAL_STORAGE.audioInputDevice);
    removeItemFromLocalStorage(LOCAL_STORAGE.audioOutputDevice);
  }, []);

  const setOutputDevice = async (e) => {
    saveItemToLocalStorage(LOCAL_STORAGE.audioOutputDevice, e, true);
  };

  const setInputDevice = async (e) => {
    saveItemToLocalStorage(LOCAL_STORAGE.audioInputDevice, e, true);
  };

  const getLogoSrc = () => {
    if (enterprise && logoURL) return logoURL;
    if (dark) return streamsLogoDark;
    return streamsLogo;
  };

  const setBackgroundImage = () => {
    if (isFirefox && !bannerURL) {
      return { backgroundImage: `url(${dark ? darkStaticBackground : lightStaticBackground})` };
    }
    if (enterprise && bannerURL) return { backgroundImage: `url("${encodeURI(bannerURL)}")` };
    return {};
  };
  return (
    <div
      className={classnames(["app-streaming-connecting-container", dark && "dark", enterprise && "enterprise"])}
      style={setBackgroundImage()}
    >
      <div className="content-box">
        <img src={getLogoSrc()} style={{ marginBottom: "0px", width: "120px" }} />
        {connected && showPlayButton ? (
          <div className="play-button-container">
            {audioInputDeviceArray.length > 1 && audioOutputDeviceArray.length > 1 && (
              <div className="output-settings-container">
                <div className="output-dropdown">
                  <p>Audio</p>
                  <div className="input-box-connecting">
                    <Dropdown
                      box
                      defaultSelected={defaultAudioOutputDevice}
                      options={audioOutputDeviceArray}
                      keyName="deviceId"
                      mapFunction={(device) => device.label}
                      handleSelectedOptionChange={(e) => {
                        setOutputDevice(e);
                      }}
                    />
                  </div>
                </div>
                <div className="output-dropdown">
                  <p>Microphone</p>
                  <div className="input-box-connecting">
                    <Dropdown
                      box
                      defaultSelected={defaultAudioInputDevice}
                      options={audioInputDeviceArray}
                      keyName="deviceId"
                      mapFunction={(device) => device.label}
                      handleSelectedOptionChange={(e) => {
                        setInputDevice(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <DivButton className="app-streaming-play-button" onClick={handlePlayButtonClick}>
              <Icon
                style={{ height: "3.5rem", width: "3.5rem" }}
                large
                name="play-button"
                color="$vg-aqua-dark"
                className="large"
              />
              <p>Join Now</p>
            </DivButton>
          </div>
        ) : (
          <>
            <p>{text}</p>
            <div className="app-streaming-progress-bar">
              <div style={{ width: `${percentage}%` }} className="progress" />
            </div>
          </>
        )}
      </div>
      {!isMobile && (
        <p className="remaining-time-info">
          <Translate
            id={`appStreamingConnecting.timeInfo.${status}`}
            options={{ onMissingTranslation: () => translate("appStreamingConnecting.timeInfo.default") }}
          />
        </p>
      )}
    </div>
  );
};

export default AppStreamingLoader;
