import React, { useEffect, useState, useRef, useMemo } from "react";
import { Translate } from "react-localize-redux";
import { Icon, DivButton, TextButton, ConfirmationModal, Button, Announcement, Modal, HoverableTooltip } from "UI";
import { LOCAL_STORAGE, REQUEST_STATUS, UPLOAD_TYPES } from "Constants/global.constants";
import {
  DashboardSubScreenLayout,
  FileStatus,
  useAPI,
  classnames,
  AddBalanceModal,
  getItemFromLocalStorage,
  isMobile,
} from "Utils";
import _ from "lodash";
import routes from "Constants/Route.constants";
import { useDispatch, useSelector } from "react-redux";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import VagonPinger from "Utils/Components/ConnectionStrength/VagonPinger";
import { APPLICATION_STATUS, VENDOR_SUBSCRIPTION_STATUS } from "Constants/AppStreaming.constants";
import WarningImage from "Assets/images/appStreaming/warning.png";
import { getStreamsAPI } from "Actions/AppStreaming.actions";
import YoutubeBackground from "Assets/images/appStreaming/youtube-background.png";
// eslint-disable-next-line max-len
import StreamsApplicationsOnboardingModal from "Components/AppStreaming/Modals/OnboardingModals/StreamsApplicationsOnboardingModal.component";
import AppListModal from "../Modals/AppListModal/AppListModal.component";
import ApplicationCards from "./Applications/ApplicationCards.component";
import AppConfigurationModal from "../Modals/AppConfigurationModal/AppConfigurationModal.component";
import AppUploadModal from "./AppUploadModal/AppUploadModal.component";
import StreamError from "../Modals/StreamError/StreamError.component";
import ActivityMonitor from "./ActivityMonitor/ActivityMonitor.component";
import ApplicationVersionsModal from "./ApplicationVersionsModal/ApplicationVersionsModal.component";
import UploadAppVersionModal from "./UploadAppVersionModal/UploadAppVersionModal.component";
import AppBudgetModal from "./AppBudgetModal/AppBudgetModal.component";
import UseCaseCard from "./UseCaseCard/UseCaseCard.component";
import AppBundleModal from "../Modals/AppBundleModal/AppBundleModal.component";
import BundledAppListModal from "../Modals/BundledAppListModal/BundledAppListModal.component";

import "./AppStreamingHome.styles.scss";

const USE_CASES = [
  {
    name: "useCase1",
    link: "https://docs.vagon.io/streams",
    video: "https://www.youtube.com/embed/1aEoDPfniCM?si=x9MUn0y-Vhh21-tj",
    thumbnail: YoutubeBackground,
  },
  {
    name: "useCase2",
    link: "https://docs.vagon.io/streams/guidelines/streams",
    video: "https://www.youtube.com/embed/EkGl_KGlhbU?si=Mblclz_-FOBu1ScI",
    thumbnail: YoutubeBackground,
  },
  {
    name: "useCase3",
    link: "https://docs.vagon.io/streams/guidelines/configurations",
    video: "https://www.youtube.com/embed/5eA6GTgPVRw?si=Uw5ZSPODUrz_Y4In",
    thumbnail: YoutubeBackground,
  },
];

const POLLING_INTERVAL = 60000;

const AppStreamingHome = ({
  account,
  appStreaming,
  testApplicationAPI,
  getApplicationsStatsAPI,
  uploadApplicationFile,
  uploadApplicationVersionFile,
  cancelFileUpload,
  filesCTX,
  getApplicationsAPI,
  getApplicationMachineTypesAPI,
  getProductOnboardingAPI,
  history,
  translate,
}) => {
  const [selectedApp, setSelectedApp] = useState({});
  const selectedAppID = selectedApp?.id;
  const [showAppList, setShowAppList] = useState(false);

  const [showDeleteAppConfirmationModal, setShowDeleteAppConfirmationModal] = useState(false);
  const [showAppConfigurationModal, setShowAppConfigurationModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showDepositBalanceModal, setShowDepositBalanceModal] = useState(false);
  const [showApplicationVersionsModal, setShowApplicationVersionsModal] = useState(false);
  const [showAppBudgetModal, setShowAppBudgetModal] = useState(false);
  const [showUploadAppVersionModal, setShowUploadAppVersionModal] = useState(false);
  const [showBalanceErrorModal, setShowBalanceErrorModal] = useState(false);
  const [initialConfig, setInitialConfig] = useState(false);
  const [showTestSessionModal, setShowTestSessionModal] = useState(false);
  const [testSessionRegion, setTestSessionRegion] = useState(null);
  const [startTestSession, setStartTestSession] = useState(false);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [onboardingModalCurrentStep, setOnboardingModalCurrentStep] = useState(0);
  const [showApplicationOnboardingModal, setShowApplicationOnboardingModal] = useState(null);
  const [displayApplication, setDisplayApplication] = useState(null);
  const [showAppBundleModal, setShowAppBundleModal] = useState(false);
  const [showBundledAppListModal, setShowBundledAppListModal] = useState(false);

  const { status: addBalanceCtxStatus } = useSelector((state) => state.payment.addBalanceCTX);

  const pollingInterval = useRef(null);

  const { getApplicationsCTX, getApplicationsStatsCTX, getCurrentPlanCTX, testApplicationCTX, getVendorAccountCTX } =
    appStreaming;

  const { subscription, balance, application_limit: applicationLimit } = getVendorAccountCTX?.data?.attributes || {};
  const {
    status: subscriptionStatus,
    price: subscriptionPrice,
    subscription_type: subscriptionType,
  } = subscription?.attributes || {};

  const subscriptionPaymentFailed = subscriptionStatus === VENDOR_SUBSCRIPTION_STATUS.payment_failed;

  const { data: productOnboardingStatesData, status: productOnboardingStatesStatus } =
    account?.productOnboardingCTX || {};
  const checkApplicationUploaded = productOnboardingStatesData?.streams?.application_uploaded;

  const applicationLimitReached = getApplicationsCTX.data?.applications?.length >= applicationLimit;

  const dispatch = useDispatch();

  useEffect(() => {
    if (testApplicationCTX.status === REQUEST_STATUS.SUCCESS && selectedAppID > 0) {
      const { connectionLink } = appStreaming.testApplicationCTX;
      window.open(connectionLink, "_blank");
      setShowTestSessionModal(false);
      setStartTestSession(false);
    }
    if (testApplicationCTX.status === REQUEST_STATUS.FAILURE && selectedAppID > 0) {
      setShowTestSessionModal(false);
      setStartTestSession(false);
      setShowBalanceErrorModal(true);
    }
  }, [testApplicationCTX.status]);

  const getApplicationsStats = () => {
    if (!showAppList) {
      if (selectedAppID > 0) getApplicationsStatsAPI(selectedAppID);
      else getApplicationsStatsAPI();
    }
  };

  const setPollingInterval = () => {
    clearInterval(pollingInterval.current);
    getApplicationsStats();
    pollingInterval.current = setInterval(getApplicationsStats, POLLING_INTERVAL);
  };

  useEffect(() => {
    setPollingInterval();
    return () => {
      clearInterval(pollingInterval.current);
    };
  }, [selectedAppID]);

  useEffect(() => {
    if (filesCTX.appUploadFilesCTX.status === REQUEST_STATUS.SUCCESS) {
      const appID = filesCTX.appUploadFilesCTX.lastAppId;
      const { os } = filesCTX.appUploadFilesCTX;
      setSelectedApp({ id: appID, attributes: { os } });
      getApplicationMachineTypesAPI(appID);
      setShowAppConfigurationModal(true);
      setInitialConfig(true);
    }
  }, [filesCTX.appUploadFilesCTX.status]);

  useEffect(() => {
    if (showTestSessionModal && !testSessionRegion) {
      const vp = new VagonPinger();
      vp.checkConnectionStrength((region) => {
        setTestSessionRegion(region);
      });
    }
  }, [showTestSessionModal]);

  useEffect(() => {
    if (startTestSession) {
      testApplicationAPI(selectedAppID, testSessionRegion);
    }
  }, [startTestSession, testSessionRegion]);

  useEffect(() => {
    if (addBalanceCtxStatus === REQUEST_STATUS.SUCCESS) {
      setTimeout(() => {
        getApplicationsAPI();
      }, 300);
    }
  }, [addBalanceCtxStatus]);

  useEffect(() => {
    dispatch(getStreamsAPI());
    getProductOnboardingAPI();
  }, []);

  useEffect(() => {
    if (showApplicationOnboardingModal === null && productOnboardingStatesStatus === REQUEST_STATUS.SUCCESS) {
      const shouldDisplayAfterCreationOnboarding =
        !isMobile && getItemFromLocalStorage(LOCAL_STORAGE.showApplicationOnboardingModal, true);
      setShowApplicationOnboardingModal(checkApplicationUploaded ? false : shouldDisplayAfterCreationOnboarding);
    }
  }, [productOnboardingStatesStatus]);

  const uploadingApps = _.filter(
    filesCTX.uploadFilesCTX.uploadFiles,
    (file) => file.uploadType === UPLOAD_TYPES.APPLICATION_UPLOAD,
  );

  const onConfigure = (app) => {
    setSelectedApp(app);
    setShowAppList(false);
    setShowAppConfigurationModal(true);
    getApplicationMachineTypesAPI(app.id);
  };

  const onShare = () => {
    history.push(routes.appStreamingStreams);
  };

  const { api: deleteAppAPI } = useAPI({
    type: "delete",
    endpoint: APP_STREAMING_API_ENDPOINTS.DELETE_APP(selectedAppID || -1),
    onSuccess: () => {
      getApplicationsAPI();
    },
  });

  const applicationList = useMemo(() => {
    return getApplicationsCTX.data?.applications?.filter((app) => app.attributes.api_scope.includes("v2")) || [];
  }, [getApplicationsCTX.status]);

  const paymentRequired = getApplicationsCTX.data?.pending_payment;
  const paymentAmount = getApplicationsCTX.data?.pending_payment_amount;
  const canCreateBundledApp = applicationList.filter((app) => app.attributes.os === "windows").length > 1;

  useEffect(() => {
    const anyPackagingApp =
      applicationList.filter((app) => app.attributes.friendly_status === APPLICATION_STATUS.PACKAGING).length > 0;
    if (getApplicationsCTX.status === REQUEST_STATUS.SUCCESS && anyPackagingApp) {
      setTimeout(() => {
        getApplicationsAPI();
      }, 15000);
    }
  }, [getApplicationsCTX.status]);

  const displayFirstTime = showApplicationOnboardingModal ? !displayApplication : applicationList.length === 0;

  return (
    <>
      <DashboardSubScreenLayout
        headerTitle={translate("appStreamingDashboard.header.title")}
        translate={translate}
        waitFor={[getApplicationsCTX.data !== null, getCurrentPlanCTX.status]}
        pending={getApplicationsStatsCTX.status === REQUEST_STATUS.PENDING}
        appStreaming
      >
        <div className="app-streaming-dashboard-container">
          {paymentRequired && (
            <Announcement
              icon={<img src={WarningImage} />}
              header={translate("appStreamingNotifications.paymentRequired.header")}
              description={
                <Translate
                  id="appStreamingNotifications.paymentRequired.description"
                  data={{ amount: paymentAmount }}
                />
              }
              inLineButton={{
                text: translate("appStreamingNotifications.paymentRequired.action"),
                onClick: () => setShowDepositBalanceModal(true),
              }}
              containerClass={classnames(["app-streaming-announcement", "vendor-payment-required-announcement"])}
            />
          )}
          {subscriptionPaymentFailed && (
            <Announcement
              icon={<img src={WarningImage} />}
              header={translate("appStreamingNotifications.subscriptionPaymentFailed.header")}
              description={
                <Translate
                  id="appStreamingNotifications.subscriptionPaymentFailed.description"
                  data={{
                    amount: (parseFloat(subscriptionPrice) - parseFloat(balance)).toFixed(2),
                    plan: translate(`streamsPlans.${subscriptionType}`),
                  }}
                />
              }
              inLineButton={{
                text: translate("appStreamingNotifications.subscriptionPaymentFailed.action"),
                onClick: () => setShowDepositBalanceModal(true),
              }}
              containerClass={classnames(["app-streaming-announcement", "vendor-payment-required-announcement"])}
            />
          )}
          {uploadingApps.length > 0 && (
            <div className="dashboard-row flex-column">
              {uploadingApps.map((file) => (
                <FileStatus
                  key={file.fileName}
                  fileName={file.fileName}
                  fileSize={file.size}
                  fileStatus={file.status}
                  fileProgress={file.progress}
                  fileNameLimit={30}
                  onCancel={cancelFileUpload}
                  error={file.error}
                  errorReason={translate("appStreamingDashboard.uploadError")}
                  block
                  translate={translate}
                />
              ))}
            </div>
          )}
          {displayFirstTime ? (
            <>
              <div className="dashboard-row application-upload-row">
                <Button
                  text={translate("appStreamingDashboard.uploadApp.buttonText")}
                  onClick={() => !showApplicationOnboardingModal && setShowFileUploadModal(true)}
                  className="upload-app-button"
                  aqua
                />
                <p>{translate("appStreamingDashboard.uploadApp.description")}</p>
              </div>
              <h2>{translate("appStreamingDashboard.useCases.header")}</h2>
              <div className="dashboard-row use-case-cards-container">
                {USE_CASES.map((useCase) => {
                  return (
                    <UseCaseCard
                      key={useCase.name}
                      logo={useCase.logo}
                      background={useCase.thumbnail}
                      translate={translate}
                      useCase={useCase}
                      onUseCaseClick={setSelectedUseCase}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <div className="dashboard-row">
                <ApplicationCards
                  applicationList={applicationList}
                  testApplicationAPI={testApplicationAPI}
                  selectedApp={selectedApp}
                  setSelectedApp={setSelectedApp}
                  setShowAppConfigurationModal={setShowAppConfigurationModal}
                  translate={translate}
                  setShowDeleteAppConfirmationModal={setShowDeleteAppConfirmationModal}
                  setShowTestSessionModal={setShowTestSessionModal}
                  setShowApplicationVersionsModal={setShowApplicationVersionsModal}
                  setShowBundledAppListModal={setShowBundledAppListModal}
                  setShowAppBudgetModal={setShowAppBudgetModal}
                  showApplicationOnboardingModal={showApplicationOnboardingModal}
                  onConfigure={onConfigure}
                  onShare={onShare}
                />
                <div className="add-new-app">
                  {!isMobile && (
                    <DivButton
                      className="add-new-app-button"
                      onClick={() => {
                        setShowFileUploadModal(true);
                      }}
                    >
                      <Icon name="plus" color="white" />
                    </DivButton>
                  )}
                  <div className="mobile-new-app">
                    {isMobile && (
                      <DivButton
                        className="mobile-add-new-app-button"
                        onClick={() => {
                          setShowFileUploadModal(true);
                        }}
                      >
                        <Icon name="plus" color="white" />
                      </DivButton>
                    )}
                    <TextButton
                      text={translate("appStreamingDashboard.newApp.header")}
                      onClick={() => {
                        setShowFileUploadModal(true);
                      }}
                      color="aqua-light"
                    />
                  </div>
                  {!isMobile && <div className="horizontal-divider" />}

                  {applicationList.length > 3 && !isMobile && (
                    <>
                      <TextButton
                        text={translate("appStreamingDashboard.newApp.totalApp", { appCount: applicationList.length })}
                        onClick={() => setShowAppList(true)}
                        color="gray-3"
                      />
                    </>
                  )}
                  <HoverableTooltip
                    content={applicationLimitReached && "Application limit is reached for your Streams Plan."}
                  >
                    <TextButton
                      text={
                        <div className="app-bundle-button">
                          <Icon name="stack" color="baby-blue-stroke" />
                          Create App Bundle
                        </div>
                      }
                      onClick={() => {
                        setShowAppBundleModal(true);
                      }}
                      color="purple"
                      disabled={!canCreateBundledApp || paymentRequired || applicationLimitReached}
                    />
                  </HoverableTooltip>
                </div>
              </div>
              <h2>{translate("appStreamingDashboard.activity.header")}</h2>
              <ActivityMonitor
                className="activity-monitor-container"
                getApplicationsStatsCTX={getApplicationsStatsCTX}
                translate={translate}
              />
            </>
          )}
          {showAppList && (
            <AppListModal
              appList={applicationList}
              selectedApp={selectedApp}
              setSelectedApp={setSelectedApp}
              onConfigure={onConfigure}
              onShare={onShare}
              setShowAppList={setShowAppList}
              testApplicationAPI={testApplicationAPI}
              setShowDeleteAppConfirmationModal={setShowDeleteAppConfirmationModal}
              translate={translate}
            />
          )}
          {showBundledAppListModal && (
            <BundledAppListModal
              selectedApp={selectedApp}
              translate={translate}
              onClose={() => {
                setShowBundledAppListModal(false);
                setSelectedApp({});
              }}
            />
          )}
          {showDeleteAppConfirmationModal && (
            <ConfirmationModal
              className="delete-app-file-confirmation-modal"
              closeOnOverlayClick
              closeAction={() => setShowDeleteAppConfirmationModal(false)}
              headerText={translate("appStreamingModals.deleteApp.header")}
              descriptionText={translate("appStreamingModals.deleteApp.description")}
              confirmText={translate("appStreamingModals.deleteApp.action")}
              confirmAction={() => {
                setShowDeleteAppConfirmationModal(false);
                deleteAppAPI(selectedAppID);
                setSelectedApp({});
              }}
              secondaryText={translate("appStreamingModals.deleteApp.secondaryAction")}
              secondaryAction={() => setShowDeleteAppConfirmationModal(false)}
            />
          )}
          {showAppConfigurationModal && (
            <AppConfigurationModal
              selectedApp={selectedApp}
              uploadedApps={filesCTX.appUploadFilesCTX.uploadedFiles}
              setShowAppConfigurationModal={setShowAppConfigurationModal}
              getApplicationsAPI={getApplicationsAPI}
              translate={translate}
              initialConfig={initialConfig}
              setInitialConfig={setInitialConfig}
              setSelectedApp={setSelectedApp}
            />
          )}
          {showFileUploadModal && (
            <AppUploadModal
              uploadingApps={uploadingApps}
              showAppConfigurationModal={showAppConfigurationModal}
              setShowFileUploadModal={setShowFileUploadModal}
              uploadApplicationFile={uploadApplicationFile}
              translate={translate}
            />
          )}
          {showDepositBalanceModal && (
            <AddBalanceModal setShowAddBalanceModal={setShowDepositBalanceModal} translate={translate} />
          )}
          {showApplicationVersionsModal && (
            <ApplicationVersionsModal
              setShowApplicationVersionsModal={setShowApplicationVersionsModal}
              setShowUploadAppVersionModal={setShowUploadAppVersionModal}
              selectedAppID={selectedAppID}
              translate={translate}
            />
          )}
          {showAppBudgetModal && (
            <AppBudgetModal
              selectedAppID={selectedAppID}
              setShowAppBudgetModal={setShowAppBudgetModal}
              getApplicationsCTX={getApplicationsCTX}
              getApplicationsAPI={getApplicationsAPI}
              appStreaming={appStreaming}
              translate={translate}
            />
          )}
          {showUploadAppVersionModal && (
            <UploadAppVersionModal
              setShowUploadAppVersionModal={setShowUploadAppVersionModal}
              uploadApplicationVersionFile={uploadApplicationVersionFile}
              selectedApp={selectedApp}
              selectedAppID={selectedAppID}
              translate={translate}
            />
          )}
          {showBalanceErrorModal && (
            <Modal
              className="test-balance-error-modal"
              descriptionText={
                <StreamError
                  translate={translate}
                  error={testApplicationCTX.error}
                  history={history}
                  setShowModal={setShowBalanceErrorModal}
                  test
                />
              }
              closeAction={() => setShowBalanceErrorModal(false)}
              topRightIcon="close"
              topRightIconAction={() => setShowBalanceErrorModal(false)}
              big
            />
          )}
          {showTestSessionModal && (
            <ConfirmationModal
              className="start-test-session-modal"
              closeAction={() => setShowTestSessionModal(false)}
              headerText={translate("appStreamingModals.startTestSession.title")}
              descriptionText={
                <>
                  <div className="description-content">
                    <Translate
                      id="appStreamingModals.startTestSession.description.main"
                      options={{
                        renderInnerHtml: true,
                      }}
                    />
                    <p>{translate("appStreamingModals.startTestSession.description.secondary")}</p>
                  </div>
                </>
              }
              secondaryText={translate("appStreamingModals.startTestSession.cancel")}
              secondaryAction={() => setShowTestSessionModal(false)}
              confirmText={
                testSessionRegion ? translate("appStreamingModals.startTestSession.confirm") : "Selecting Region"
              }
              confirmAction={() => {
                setStartTestSession(true);
              }}
              confirmButtonIcon={!testSessionRegion ? "loader" : null}
              disableConfirmButton={!testSessionRegion || testApplicationCTX.status === REQUEST_STATUS.PENDING}
              pending={testApplicationCTX.status === REQUEST_STATUS.PENDING}
            />
          )}

          {selectedUseCase && (
            <Modal
              closeOnOverlayClick
              fullSize
              big
              closeAction={() => setSelectedUseCase(null)}
              overlayClassName="youtube-video-modal-overlay"
            >
              <iframe
                width="100%"
                height="500"
                src={selectedUseCase.video}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </Modal>
          )}
          {showApplicationOnboardingModal && (
            <StreamsApplicationsOnboardingModal
              setCurrentStep={setOnboardingModalCurrentStep}
              onExit={() => {
                setShowApplicationOnboardingModal(false);
                localStorage.setItem(LOCAL_STORAGE.showApplicationOnboardingModal, false);
              }}
              setDisplayApplication={setDisplayApplication}
              translate={translate}
            />
          )}
          {showAppBundleModal && (
            <AppBundleModal
              selectedApp={selectedApp}
              appStreaming={appStreaming}
              uploadedApps={filesCTX.appUploadFilesCTX.uploadedFiles}
              setShowAppConfigurationModal={setShowAppConfigurationModal}
              getApplicationsAPI={getApplicationsAPI}
              translate={translate}
              initialConfig={initialConfig}
              setInitialConfig={setInitialConfig}
              setSelectedApp={setSelectedApp}
              applications={applicationList}
              getApplicationMachineTypesAPI={getApplicationMachineTypesAPI}
              onClose={() => {
                setShowAppBundleModal(false);
              }}
              subscriptionType={subscriptionType}
            />
          )}
        </div>
      </DashboardSubScreenLayout>
    </>
  );
};

export default AppStreamingHome;
