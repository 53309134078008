/* eslint-disable max-len */
import React, { useEffect, useRef, useState } from "react";
import * as MessageHelper from "Utils/Workstation/messageHelper";
import { useQuery } from "@tanstack/react-query";
import { API_ENDPOINTS } from "Constants/api.constants";
import {
  LOCAL_STORAGE,
  WORKSTATION_FRIENDLY_STATUS,
  STREAM_TYPE,
  INTERNAL_SDK_MESSAGES,
} from "Constants/global.constants";
import { apiGenerator, getClientInfo, isMobile, Logger } from "Utils";
import {
  startPixelStreaming,
  connect,
  fullscreen,
  requestPointerLockForPlayer,
  setOnGameModeDisabled,
  resetAfkWarningTimer,
} from "Utils/PixelStreaming/index";
import routes from "Constants/Route.constants";
import {
  DOCK_POSITIONS,
  DOCK_VISIBILITY,
  ERRORS,
  ERROR_CODE,
  SOUND_AND_MICROPHONE_OPTIONS,
} from "Constants/AppStreaming.constants";
import {
  ToastNotificationContainer,
  showToastNotification,
} from "Components/Workstation/ToastNotification/ToastNotification";
import AppStreamingLoader from "Components/AppStreaming/AppStreamingLoader/AppStreamingLoader.component";
import AppStreamingDock from "Components/AppStreaming/AppStreamingDock/AppStreamingDock.component";
import { useHotkeys } from "react-hotkeys-hook";

import { getItemFromLocalStorage, saveItemToLocalStorage } from "Utils/Helpers/storage.helpers";
import WebRTC from "Utils/Connection/webrtc";
import SDKInternal from "Utils/Workstation/SDKInternal";
import IframeModal from "Components/Workstation/Modals/IframeModal/IframeModal.component";
import { getDeviceType } from "Utils/Helpers/browser.helpers";
import AppStreamingKeyboardButton from "Components/Workstation/WorkstationDock/KeyboardButton/KeyboardButton.component";

import "./PixelStreaming.styles.scss";

export function Hotkey({ hotkey, callback }) {
  useHotkeys(hotkey, callback);
  return null;
}

const PixelStreamingContainer = ({ history, translate, match }) => {
  const [connecting, setConnecting] = useState(false);
  const isPlayButtonClickedRef = useRef(false);
  const [isConnected, setIsConnected] = useState(false);
  const [isDockOpen, setIsDockOpen] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [quality, setQuality] = useState("standard");
  const [micEnabled, setMicEnabled] = useState(false);
  const [isGameModeDisabledNotificationShown, setIsGameModeDisabledNotificationShown] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  const [showKeyboardButton, setShowKeyboardButton] = useState(false);

  const sessionExpireTimeoutRef = useRef(null);
  const webRtc = useRef(null);

  const streamUid = match.params?.uri;

  const sendParentMessageAndRedirect = (error) => {
    const message = JSON.stringify({ error });
    window.parent.postMessage(message, "*");

    setTimeout(() => {
      history.push({
        pathname: routes.appStreamingError,
        streamID,
        error: ERRORS.SESSION_IDLE,
      });
    }, 1000);
  };

  const { data: machineData } = useQuery({
    queryKey: [API_ENDPOINTS.APP_STREAMING_SESSION(streamUid)],
    queryFn: () => apiGenerator("get")(API_ENDPOINTS.APP_STREAMING_SESSION(streamUid)),
    enabled: !!streamUid,
    refetchInterval: 7000,
    keepPreviousData: true,
    onError: (err) => {
      const { client_code: clientCode } = err?.response?.data;

      let error = ERRORS.STREAM_NOT_FOUND;
      if (clientCode === ERROR_CODE.INSTALLATION_FAILED) {
        error = ERRORS.INSTALLATION_FAILED;
      }

      if (clientCode === ERROR_CODE.SESSION_EXPIRED) {
        error = ERRORS.SESSION_EXPIRED;
      }

      sendParentMessageAndRedirect(error);
    },
  });

  const machine = machineData?.data;

  const {
    friendly_status: friendlyStatus,
    application,
    dark_mode: darkMode,
    texts,
    dock_position: dockPosition,
    key_mapping_selection: keyMappingSelection,
    microphone,
    turn_uri: turnUri,
    turn_password: turnPassword,
    turn_credentials: turnCredentials,
    maximum_session_duration: maxDuration,
    active_session_start_at: startAt,
    maximum_session_duration_enabled: maxSessionDurationEnabled,
    active_stream_id: streamID,
  } = machine?.attributes || {};

  const { banner_url: bannerURL, logo_url: logoURL, enterprise, name } = application?.attributes || {};

  useEffect(() => {
    if (maxSessionDurationEnabled && startAt) {
      const estimatedRemainingSeconds = new Date(startAt).getTime() + maxDuration * 60 * 1000 - new Date().getTime();

      const timeout = setTimeout(() => {
        showToastNotification({
          description: translate("sessionExpireMessage"),
          autoClose: 50000,
        });
      }, estimatedRemainingSeconds - 60000);

      if (sessionExpireTimeoutRef.current) {
        clearTimeout(sessionExpireTimeoutRef.current);
      }
      sessionExpireTimeoutRef.current = timeout;
    }
  }, [maxSessionDurationEnabled, maxDuration, startAt]);

  const onIdleTimeEnd = () => {
    const message = JSON.stringify({ error: ERRORS.SESSION_IDLE });
    window.parent.postMessage(message, "*");
    sendParentMessageAndRedirect(ERRORS.SESSION_IDLE);
  };

  const playButtonIdleTimeEnd = () => {
    sendParentMessageAndRedirect(ERRORS.SESSION_IDLE);
  };

  const onWarningTimeEnd = () => {
    showToastNotification({
      description: translate("sessionIdleMessage"),
    });
  };

  const playButtonWarningEnd = () => {
    showToastNotification({
      description: translate("pixelStreamingSessionIdleMessage"),
      autoClose: 10000,
    });
  };

  const initInternalWebRTC = () => {
    if (webRtc.current) {
      webRtc.current = null;
    }

    webRtc.current = new WebRTC(
      appSessionId(),
      null,
      turnUri,
      turnPassword,
      STREAM_TYPE.pixel_streaming,
      machine.id,
      turnCredentials,
      null,
      {
        password: machine?.attributes?.password,
      },
    );

    webRtc.current.onopen = onOpen;
    webRtc.current.onclose = onClose;
    webRtc.current.onmessage = onMessage;
  };

  const onOpen = () => {
    SDKInternal.setConnected(true);
    setTimeout(() => {
      sendMessage(MessageHelper.generateApplicationEventConnectionMessage(true));
    }, 1000);
    SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onConnected);
  };

  const onClose = () => {
    SDKInternal.setConnected(false);
    sendMessage(MessageHelper.generateApplicationEventConnectionMessage(false));
    SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onDisconnected);
    setTimeout(() => {
      initInternalWebRTC();
    }, 1000);
  };

  const onMessage = (e) => {
    const message = MessageHelper.parseMessageString(e.data);
    Logger.log("Websocket|Received message:", message, e.data);
    const msgType = message.$type.toString();
    if (
      msgType !== MessageHelper.messageTypes.applicationMessage &&
      msgType !== MessageHelper.messageTypes.actionTrigger
    ) {
      Logger.log("Websocket|Unrealted Message Received:", message);
      return;
    }

    switch (msgType) {
      case MessageHelper.messageTypes.applicationMessage:
        SDKInternal.sendToAllParentIframes(message.p);
        break;
      case MessageHelper.messageTypes.actionTrigger:
        onActionTrigger(message);
        break;
      default:
        break;
    }
  };

  const onActionTrigger = (message) => {
    switch (message.at) {
      case MessageHelper.actionTriggerTypes.showVimeoPopUp:
      case MessageHelper.actionTriggerTypes.showYouTubePopUp:
        setIframeSrc(message.p);
        break;
      case MessageHelper.actionTriggerTypes.endSession:
        appSessionId(true);
        webRtc.current.onclose = null;
        webRtc.current.close();
        history.push(routes.appStreamingEnded);
        break;
      case MessageHelper.actionTriggerTypes.openUrl:
        window.open(message.p, "_blank");
        break;
      case MessageHelper.actionTriggerTypes.showKeyboard:
      case MessageHelper.actionTriggerTypes.hideKeyboard:
      case MessageHelper.actionTriggerTypes.enableGameMode:
      case MessageHelper.actionTriggerTypes.disableGameMode:
      case MessageHelper.actionTriggerTypes.resize:
      case MessageHelper.actionTriggerTypes.setQuality:
        // ignored for pixel streaming
        break;
      case MessageHelper.actionTriggerTypes.keepAlive:
        resetAfkWarningTimer();
        break;
      case MessageHelper.actionTriggerTypes.focusIframe:
        SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.focusIframe);
        break;
      case MessageHelper.actionTriggerTypes.requestSessionInformation: {
        const payload = MessageHelper.generateApplicationEventSessionInformationMessage(prepareSessionInformation());
        if (enterprise) {
          sendMessage(payload);
        }
        break;
      }
      case MessageHelper.actionTriggerTypes.onShutDown:
        sendMessage(MessageHelper.generateApplicationEventShutdownMessage());
        setTimeout(onShutdown, 2000);
        break;
      default:
        break;
    }
  };

  const onShutdown = () => {
    if (enterprise) {
      SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onFailed);
    }
    const message = JSON.stringify({ error: ERRORS.STREAM_NOT_FOUND });
    window.parent.postMessage(message, "*");
    setTimeout(() => {
      history.push(routes.workstation404);
    }, 3000);
  };

  const prepareSessionInformation = () => {
    const { os } = getClientInfo();
    const deviceType = getDeviceType();
    // TODO: We need to get ping
    // const ping = await this.getRegionPing(region);

    const metricsData = {
      machine_uid: machine.id,
      os,
      device_type: deviceType,
      ping: 0,
      local_identifier: appSessionId(),
      connection_type: "pixel_streaming",
    };
    return metricsData;
  };

  const appSessionId = (override = false) => {
    let appSessionId = getItemFromLocalStorage(LOCAL_STORAGE.appSessionId);
    if (!appSessionId || override) {
      appSessionId = Math.random().toString(36).substr(2, 9);
    }
    saveItemToLocalStorage(LOCAL_STORAGE.appSessionId, appSessionId);
    return appSessionId;
  };

  const sendMessage = (msg) => {
    Logger.log("sendMessage", msg, webRtc?.readyState, this);
    if (webRtc?.current?.readyState === WebSocket.OPEN) {
      webRtc.current.send(msg);
      Logger.log("Websocket|Sent message:", msg);
    }
  };

  const internalSDKCallback = (message) => {
    Logger.log("internalSDKCallback", message);
    switch (message) {
      case INTERNAL_SDK_MESSAGES.keepAlive:
        resetAfkWarningTimer();
        break;
      case INTERNAL_SDK_MESSAGES.shutdown:
        sendMessage(MessageHelper.generateApplicationEventShutdownMessage());
        setTimeout(onShutdown, 1000);
        break;
      case INTERNAL_SDK_MESSAGES.setQualityStandard:
      case INTERNAL_SDK_MESSAGES.setQualityHigh:
      case INTERNAL_SDK_MESSAGES.setQualityModerate:
      case INTERNAL_SDK_MESSAGES.resize:
      case INTERNAL_SDK_MESSAGES.showKeyboard:
      case INTERNAL_SDK_MESSAGES.hideKeyboard:
      case INTERNAL_SDK_MESSAGES.enableGameMode:
      case INTERNAL_SDK_MESSAGES.disableGameMode:
        // ignored for pixel streaming
        break;
      case INTERNAL_SDK_MESSAGES.requestSessionInformation: {
        const data = prepareSessionInformation();
        const information = JSON.stringify(data);
        Logger.log("requestSessionInformation Before", information);
        if (enterprise) {
          SDKInternal.sendToAllParentIframes(INTERNAL_SDK_MESSAGES.onSessionInformation + information);
          Logger.log("requestSessionInformation Sent", information);
        }
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (machine && machine?.attributes?.friendly_status === WORKSTATION_FRIENDLY_STATUS.READY && !isConnected) {
      startPixelStreaming({
        streamUid,
        idleDuration: machine.attributes.idle_duration,
        onIdleTimeEnd,
        onWarningTimeEnd,
        setShowLoader,
        keyMappingSelection,
        setConnecting,
        onGameModeDisabled,
        microphone: SOUND_AND_MICROPHONE_OPTIONS.activate_on_start === microphone,
        audioInputDeviceId: getItemFromLocalStorage(LOCAL_STORAGE.audioInputDevice, null)?.deviceId,
        audioOutputDeviceId: getItemFromLocalStorage(LOCAL_STORAGE.audioOutputDevice, null)?.deviceId,
      });
      setIsConnected(true);
      setTimeout(() => {
        if (!isPlayButtonClickedRef.current) {
          playButtonWarningEnd();
        }
      }, 45000);
      setTimeout(() => {
        if (!isPlayButtonClickedRef.current) {
          playButtonIdleTimeEnd();
        }
      }, 60000);
      SDKInternal.start({
        sendApplicationEvent,
      },
        internalSDKCallback,
      );
      Logger.log("pixelStreamingStarted");
    }
  }, [machine]);

  // eslint-disable-next-line no-unused-vars
  const sendApplicationEvent = (message) => {
    sendMessage(MessageHelper.generateApplicationEventMessage(message));
  };

  const changeSoundState = (state) => {
    setSoundEnabled(state);
    const player = document.getElementById("streamingAudio");
    if (player) {
      player.muted = !state;
    }
  };

  const toggleFullscreen = () => {
    fullscreen();
  };

  const toggleMic = () => {
    setMicEnabled(!micEnabled);
    // handle mic
  };

  const onGameModeDisabled = () => {
    if (!isGameModeDisabledNotificationShown) {
      setTimeout(() => {
        showToastNotification({
          header: translate("360viewMode.disabled.header"),
          description: translate("360viewMode.disabled.description"),
        });
      }, 10);
      setIsGameModeDisabledNotificationShown(true);
    }
  };

  useEffect(() => {
    setOnGameModeDisabled(onGameModeDisabled);
  }, [isGameModeDisabledNotificationShown]);

  return (
    <div className="pixelstreaming-container">
      <Hotkey
        hotkey="ctrl+g"
        callback={() => {
          requestPointerLockForPlayer();
        }}
      />
      <div id="playerUI" className="noselect">
        <div id="playerPixel">
          {machine && showLoader && (
            <div className="loading-container">
              <AppStreamingLoader
                status={connecting ? "ready" : friendlyStatus}
                texts={texts}
                dark={darkMode}
                enterprise={enterprise}
                bannerURL={bannerURL}
                logoURL={logoURL}
                translate={translate}
                appName={name}
                connected
                showPlayButton={!connecting}
                initialPercentage={85}
                microphone={microphone}
                handlePlayButtonClick={() => {
                  setConnecting(true);
                  connect({
                    microphone: SOUND_AND_MICROPHONE_OPTIONS.activate_on_start === microphone,
                    audioInputDeviceId: getItemFromLocalStorage(LOCAL_STORAGE.audioInputDevice, null)?.deviceId,
                    audioOutputDeviceId: getItemFromLocalStorage(LOCAL_STORAGE.audioOutputDevice, null)?.deviceId,
                  });
                  isPlayButtonClickedRef.current = true;
                  initInternalWebRTC();
                }}
              />
            </div>
          )}
        </div>
      </div>
      <ToastNotificationContainer />
      {iframeSrc && <IframeModal setShowIframeModal={setIframeSrc} src={iframeSrc} />}
      {isConnected && !isMobile && dockPosition !== DOCK_POSITIONS.hide && (
        <AppStreamingDock
          isDockOpen={isDockOpen}
          displayDockButton={false}
          setIsDockOpen={setIsDockOpen}
          translate={translate}
          soundEnabled={soundEnabled}
          changeableSound={false}
          dockVisibility={DOCK_VISIBILITY.always_visible}
          dockColor=""
          changeSoundState={changeSoundState}
          quality={quality}
          setQuality={setQuality}
          changeableQuality={false}
          toggleFullscreen={toggleFullscreen}
          isMicEnabled={micEnabled}
          toggleMic={toggleMic}
          gameModeEnabled={false}
          changeGameMode={() => { }}
          submitStreamPreference={() => { }}
          connected={isConnected}
          dockPosition={dockPosition}
          clickToStart={showLoader}
          pixelStreaming
        />
      )}
      {showKeyboardButton && isMobile && <AppStreamingKeyboardButton />}
    </div>
  );
};

export default PixelStreamingContainer;
